@charset "UTF-8";
/* CSS Document */


/*本番反映済み
--------------------------------------------------------------------------------------------------------------------------------------------*/

/* SALEの時 固有のCSS */

	.is_sale_menu {
		display: none;
	}
  .hm__nav__block__menu.block__menu__journal {
    order: 2;
  }
	.is-active .hm-header__list li:nth-of-type(6) {
		opacity: 0;
		pointer-events: none;
	}
	@media (max-width:767px) {
		.is_sale_menu {
      display: block;
			margin-bottom: 40px;
		}
		.is_sale_menu .hm__nav__block__menu__cate {
      display: flex;
		}
		.is_sale_menu .hm__nav__block__menu__cate .__left {
      width: calc(50%);
		}
		.is_sale_menu .hm__nav__block__menu__cate .__right {
      width: 50%;
		}
    .is_sale_menu .hm__nav__block__menu__cate .__left ul li,
    .is_sale_menu .hm__nav__block__menu__cate .__right ul li {
      font-size: 1.3rem;
    }
	}



/*amazon pay調整*/

@media only screen and (min-width: 834px){
.pt_cart #main .cart-actions .amazon-btn-right, .pt_checkout #main .cart-actions .amazon-btn-right {
clear: both;
float: right;
width: 296px;
float: none;
text-align: right;
margin-top: 20px;
}


.pt_cart #main .cart-actions .amazon-btn-right .amazon-button img, .pt_checkout #main .cart-actions .amazon-btn-right .amazon-button img {
width: 296px;
max-width: 100%;
max-height: auto;
}

	.pt_cart #main .cart-actions .amazon-btn-right p, .pt_checkout #main .cart-actions .amazon-btn-right p {
font-size:13px !important;
margin-top: 20px;
}

}


@media only screen and (max-width: 833px){
	.pt_cart #main .cart-actions .amazon-btn-right p, .pt_checkout #main .cart-actions .amazon-btn-right p {
font-size:13px !important;
margin-top: 20px;
		line-height: 1.5em;
}
}


/*新規登録調整*/

.uq_new_account .custom-attribute-wrapper .attrItem .input-checkbox  {
-webkit-appearance: none;
appearance: none;
-moz-appearance: none;
outline: none;
position: relative;
width: 18px;
height: 18px;
min-height: 18px;
margin: 0;
border: 1px solid #aaa;
border-radius: 0;
font-size: 14px;
top: 1px;
margin-right: 8px;
outline: none;
}

.uq_new_account #customAttribute1-container {
padding-top: 20px;
}



.uq_new_account #customAttribute1-container label[for="customAttribute1"] {
margin-bottom: 20px !important;
}



@media only screen and (min-width: 834px){



.uq_new_account #customAttribute1-container label {
width: 120px;
margin-right: 20px;
margin-top: 5px;
font-size: 1.3rem;
}

.uq_new_account #customAttribute1-container label span {
padding-top: 10px;
display: inline-block;
}

}


.uq_new_account .md_bold  {
font-weight: bold;
padding: 0;
}
.uq_new_account form legend, .uq_new_account ._title, .uq_new_account #dialog-formcontrol-input-control .field-wrapper  {
position: relative;
}

.uq_new_account .pt_account #main .primary-content h1{
position: relative !important;
}


.uq_new_account ._point  {
color: #d81616;
font-size: 12px;
position: absolute;
top: 50%;
margin-top: -9px;
left: 50%;
padding: 0 5px;
box-sizing: border-box;
}
.uq_new_account #dialog-formcontrol-input-control .field-wrapper {
width: 100%;
}


@media (max-width:767px) {
.uq_new_account ._point  {
position: static;
display: inline-block;
vertical-align: top;
margin-top: 0;
}
.uq_new_account ._text  {
width: 41%;
line-height: 1.4;
}

}